import "./css/index.css";
import { Link, useLocation } from "react-router-dom";
import { UserAgentType } from "../../interface/common/common.inferface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupChange } from "../../reducer/config";
import { RootState } from "../../reducer";
import { auth, db } from "../../config/firebase";
import { getDoc, doc } from "firebase/firestore";
import { AuthType, ResetAuth, setAuthState } from "../../reducer/auth";
import { signOut } from "firebase/auth";
type Props = {
  userAgent: UserAgentType;
};
const Header = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogin = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const [toggleOpen, setToggleOpen] = useState<boolean>(false);
  const [textClick, setTextClick] = useState<number | null>(null);
  const ToggleOpenClick = () => {
    setToggleOpen((prev) => !prev);
  };
  const TextClick = (idx: number) => {
    setTextClick(idx);
  };
  useEffect(() => {
    document.getElementById("root")?.scrollTo(0, 0);
    setToggleOpen(false);
    return () => {};
  }, [location]);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user && user.uid) {
        const userData = await getDoc(doc(db, "user", user.uid));
        if (userData.exists()) {
          const data = userData.data() as AuthType;
          dispatch(setAuthState(data));
        }
      } else {
        dispatch(ResetAuth());
      }
    });

    return () => {};
  }, []);

  return (
    <header className="header-wrapper">
      {userAgent === "pc" && (
        <div className="header-container">
          <div className="logo-img-container">
            <Link to="/">SKPOS</Link>
          </div>
          <nav className="header-nav">
            <ul className="header-ul">
              {menuArr.map((item, idx) => (
                <li className="header-menu" key={idx}>
                  <Link to={item.link}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="auth-container">
            <div className={`auth-box ${isLogin && "logout-box"}`}>
              {isLogin ? (
                <button
                  onClick={() => {
                    signOut(auth);
                  }}
                >
                  로그아웃
                </button>
              ) : (
                <button
                  onClick={() => {
                    dispatch(
                      PopupChange({
                        active: true,
                        type: "login",
                      })
                    );
                  }}
                >
                  로그인
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {(userAgent === "tablet" || userAgent === "mb") && (
        <div className="mb-header">
          {toggleOpen ? (
            // toggle 오픈했을 때
            <div className="toggle-open-container">
              <div className="cancel-container">
                <img
                  src="/assets/cancel.svg"
                  alt="cancel"
                  onClick={ToggleOpenClick}
                />
              </div>
              <div className="mb-menulist-wrapper">
                <ul className="mb-menulist-container">
                  {menuArr.map((item, idx) => (
                    <li
                      key={idx}
                      className={`lists`}
                      onClick={() => TextClick(idx)}
                    >
                      <Link
                        to={item.link}
                        className={idx === textClick ? "text-click" : ""}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div className="mb-header-container">
              <div className="logo-img-container">
                <Link to="/">SKPOS</Link>
              </div>
              <div className="mb-header-right-section">
                <div className="auth-container">
                  <div className={`auth-box ${isLogin && "logout-box"}`}>
                    {isLogin ? (
                      <button
                        onClick={() => {
                          signOut(auth);
                        }}
                      >
                        로그아웃
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          dispatch(
                            PopupChange({
                              active: true,
                              type: "login",
                            })
                          );
                        }}
                      >
                        로그인
                      </button>
                    )}
                  </div>
                </div>
                <button className="hamburger-menubar" onClick={ToggleOpenClick}>
                  <img src="/assets/hamburger.svg" alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </header>
  );
};
export default Header;
const menuArr = [
  {
    title: "도입업종",
    link: "/industry",
  },
  {
    title: "주요기능",
    link: "/skill",
  },
  // {
  //   title: "카드단말기",
  //   link: "/terminal",
  // },
  {
    title: "다운로드",
    link: "/down",
  },
  // {
  //   title: "가맹절차",
  //   link: "/procedure",
  // },
  {
    title: "자주묻는질문",
    link: "/faq",
  },
  {
    title: "고객문의",
    link: "/user/inquiry",
  },
];
