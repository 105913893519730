import React, { useCallback, useReducer, useState } from "react";
import InquiryWrite from "../InquiryWrite";
import { UserAgentType } from "../../../interface/common/common.inferface";
import { InquiryInitial, InquiryReducer } from "../reducer/InquiryReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { AuthType } from "../../../reducer/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { LoadingChange, ToastChange } from "../../../reducer/config";
import { useNavigate } from "react-router-dom";

type Props = {
  userAgent: UserAgentType;
};

const InquiryWriteContainer = ({ userAgent }: Props) => {
  const navigate = useNavigate();
  const reduxPatch = useDispatch();
  const userData = useSelector<RootState, AuthType>((state) => state.auth);
  const [state, dispatch] = useReducer(
    InquiryReducer,
    undefined,
    InquiryInitial
  );
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const isValid = useCallback(() => {
    const { sectors, tel, company, content } = state;
    if (isAgree && sectors && tel && company && content) {
      return true;
    }
    return false;
  }, [state, isAgree]);

  const SubmitInquiry = useCallback(async () => {
    if (isValid()) {
      reduxPatch(LoadingChange(true));
      const { name, email, uid } = userData;
      const obj = Object.assign(state, {
        name,
        email,
        timestamp: Date.now(),
        uid,
      });
      await addDoc(collection(db, "inquiry"), obj);
      reduxPatch(
        ToastChange({
          active: true,
          content: "문의를 완료하였습니다",
        })
      );
      navigate("/user/inquiry");
      reduxPatch(LoadingChange(false));
    }
  }, [isValid, state]);

  return (
    <InquiryWrite
      userAgent={userAgent}
      data={state}
      dispatch={dispatch}
      isValid={isValid}
      isAgree={isAgree}
      setIsAgree={setIsAgree}
      SubmitInquiry={SubmitInquiry}
    />
  );
};

export default InquiryWriteContainer;
