import React, { useEffect, useState } from "react";
import "./css/index.css";
import { ReactComponent as Boxsvg } from "./svg/box.svg";
import { ReactComponent as Cardsvg } from "./svg/card.svg";
import { ReactComponent as Chitsvg } from "./svg/chit.svg";
import { ReactComponent as Clicksvg } from "./svg/click.svg";
import { ReactComponent as Pricesvg } from "./svg/price.svg";
import { ReactComponent as Purchasesvg } from "./svg/purchase.svg";
import { ReactComponent as Marketsvg } from "./svg/market.svg";
import { ReactComponent as Uploadsvg } from "./svg/upload.svg";
import { ReactComponent as Categorysvg } from "./svg/category.svg";
import { ReactComponent as Backsvg } from "./svg/back.svg";
import { ReactComponent as Checksvg } from "./svg/check.svg";
import ServiceSpotlight from "../../components/ServiceSpotlight/ServiceSpotlight";
import Technology from "../../components/Technology/Technology";
import AffiliateCompany from "../../components/AffiliateCompany/AffiliateCompany";
import Bottom from "../../components/Bottom/Bottom";
import Footer from "../../components/Footer/Footer";
import { UserAgentType } from "../../interface/common/common.inferface";
import VerticalSlider from "../../components/VerticalSlider/VerticalSlider";
import FirstPopup from "../../components/FirstPopup/FirstPopup";
import Floating from "../../components/Floating/Floating";
type Props = {
  userAgent: UserAgentType;
};

const Main = ({ userAgent }: Props) => {
  const topTwo = section4Arr.slice(0, 2);
  const bottomThree = section4Arr.slice(2);

  return (
    <main className="main-page">
      <section
        className="section1"
        style={{
          backgroundImage: `url(/assets/mainimg/mainimg.png)`,
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="section1-img-container">
          <div className="title-container">
            <div className="title-text-container">
              {/* <img
                src="/assets/mainimg/titleimg.png"
                srcSet={`
                  /assets/mainimg/titleimg@2.png, /assets/mainimg/titleimg@3.png
                `}
                alt=""
              /> */}
              <div className="title-text">
                누구나 쉽게 사용하도록 <br /> 제대로 만든 <br />
                <b> Well Made </b>
              </div>
              <a href="/industry" className="more-button">
                자세히 알아보기
              </a>
            </div>
            <div className="product-img-container">
              {/* <img
                src="/assets/mainimg/newproduct.png"
                srcSet={`
                  /assets/mainimg/newproduct@2.png, /assets/mainimg/newproduct@3.png
                `}
                alt=""
              /> */}
            </div>
          </div>
        </div>
        {/* <VerticalSlider slideArr={slideArr} userAgent={userAgent} /> */}
      </section>

      <section className="section2">
        <div className="section2-wrapper">
          <div className="container">
            <div className="bubble">
              <p>All free 라이센스</p>
            </div>
            <div className="section2-title">
              {userAgent !== "mb"
                ? ` 포스 무료로 사용하세요
100세까지 비용없이 평생무료`
                : `포스 무료로 사용하세요
100세까지 비용없이
평생무료`}
            </div>
            <img
              src="/assets/mainimg/allfree.png"
              srcSet={`
                /assets/mainimg/allfree@2.png 2x, /assets/mainimg/allfree@3.png 3x
              `}
              className="allfree-img"
              alt="allfree"
            />
            <div className="card-container">
              {section2Arr.map((item, idx) => (
                <div className="card" key={idx}>
                  <span className="card-title">{item.title}</span>
                  <img
                    src={`/assets/mainimg/${item.src}.png`}
                    srcSet={`
                    /assets/mainimg/${item.src}@2.png 2x, /assets/mainimg/${item.src}@3.png 3x}`}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section3">
        <div className="section3-wrapper">
          <div className="section3-title-container">
            <p className="section3-title">쉽게 더 쉽게</p>
            <p className="section3-subtitle">
              {userAgent !== "mb"
                ? `누구나, 오늘 출근한 알바도,
뚝닥뚝닥 포스 교육이 필요없습니다`
                : `누구나, 오늘 출근한 알바도,
뚝닥뚝닥 포스 교육이
필요없습니다`}
            </p>
          </div>
          <div className="section3-card-container">
            {section3Arr.map((item, idx) => (
              <div className="section3-card" key={idx}>
                <div className={`section3-card-title blue${idx}`}>
                  {item.title}
                  {item.subtitle}
                </div>
                <img src={item.src} srcSet={`${item.setSrc}`} alt="" />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section4">
        <div className="section4-wrapper">
          <div className="section4-title-container">
            <p className="section4-title">다양한 페이 호환</p>
            <p className="section4-subtitle">
              {userAgent !== "mb" ? (
                <>
                  네이버, 애플 페이 등 <br />
                  <span>모든 페이로 결제 받을 수 있습니다</span>
                </>
              ) : (
                <>
                  네이버, 애플 페이 등 <br />
                  <span>
                    모든 페이로 결제 <br /> 받을 수 있습니다
                  </span>
                </>
              )}
            </p>
          </div>
          <div className="circle-container">
            {userAgent === "pc" &&
              section4Arr.map((item, idx) => (
                <div className="circle-card" key={idx}>
                  <img
                    src={`/assets/mainimg/${item.src}.png`}
                    srcSet={`/assets/mainimg/${item.src}@2x.png 2x, /assets/mainimg/${item.src}@3x.png 3x`}
                    alt=""
                  />
                </div>
              ))}
            {userAgent === "tablet" ||
              (userAgent === "mb" && (
                <>
                  <div className="card-container">
                    {topTwo.map((item, idx) => (
                      <div className="circle-card">
                        <img
                          src={`/assets/mainimg/${item.src}.png`}
                          srcSet={`/assets/mainimg/${item.src}@2x.png 2x, /assets/mainimg/${item.src}@3x.png 3x`}
                          alt={item.src + idx}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="card-container">
                    {bottomThree.map((item, idx) => (
                      <div className="circle-card" key={idx}>
                        <img
                          src={`/assets/mainimg/${item.src}.png`}
                          srcSet={`/assets/mainimg/${item.src}@2x.png 2x, /assets/mainimg/${item.src}@3x.png 3x`}
                          alt={item.src + idx}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ))}
          </div>
        </div>
      </section>
      <ServiceSpotlight
        title=""
        thinSubtitle="편리함은 더 편리하게,"
        boldSubtitle="업무 효율을 높이는 간편한 기능"
        menuArr={serviceMenuArr}
        type="work"
      />
      <ServiceSpotlight
        title=""
        thinSubtitle="사장님들의 만족도가 높은"
        boldSubtitle="주요 기능을 소개 합니다"
        menuArr={serviceCeoMenuArr}
        type="ceo"
      />
      <section className="section7">
        <div className="section7-wrapper">
          <div className="section7-title-container">
            <p className="title-color">고객 마케팅</p>
            <p className="title-bold">
              이미지 알림톡으로 <br />
              단골을 위한 마케팅 방법
            </p>
            <p className="section7-subtitle">
              {userAgent !== "mb"
                ? "이미지 알림톡으로 고객 마케팅과 주문접수를 동시에"
                : `이미지 알림톡으로
고객 마케팅과 주문접수를 동시에`}
            </p>
          </div>
          <div className="marketing-img-conainer">
            {section7Arr.map((item, idx) => (
              <div className="marketing-card" key={idx}>
                <img
                  src={`/assets/customermarketing/${item.src}.png`}
                  srcSet={`
                    /assets/customermarketing/${item.src}@2x.png 2x, /assets/customermarketing/${item.src}@3x.png 3x
                `}
                  alt=""
                />
                <div className="marketing-title-container">
                  <p className="marketing-step">{item.step}</p>
                  <p className="marketing-title">{item.title}</p>
                </div>
              </div>
            ))}
            <div className="long-arrow">
              <img
                src="/assets/longarrow.png"
                srcSet="/assets/longarrow@2.png, /assets/longarrow@3.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Technology />
      <AffiliateCompany />
      <Bottom />
      <Footer />
      <FirstPopup />
      <Floating />
    </main>
  );
};

export default Main;

const section2Arr = [
  {
    title: "가입비 0원",
    src: "mony",
  },
  {
    title: "월비용 0원",
    src: "calculator",
  },
  {
    title: "기능제한 없음",
    src: "limit",
  },
  {
    title: "구독 기능제한 없음",
    src: "laptop",
  },
];
const section3Arr = [
  {
    title: (
      <p className="card3-title">
        인터넷 연결 없이 <br />
        사용가능
      </p>
    ),
    subtitle: (
      <p className="card3-subtitle">
        인터넷 연결이 되지 않아도 <br />
        언제든지 현황 확인
      </p>
    ),
    src: "/assets/mainimg/internet.png",
    setSrc:
      "/assets/mainimg/internet@2.png 2x, /assets/mainimg/internet@3.png 3x",
  },
  {
    title: (
      <p className="card3-title">
        포스기, 노트북, PC <br />
        모두 설치 가능
      </p>
    ),
    subtitle: (
      <p className="card3-subtitle">
        포스기, 노트북, PC 등 <br />
        다양한 환경에도 설치가 가능
      </p>
    ),
    src: "/assets/mainimg/force.png",
    setSrc: "/assets/mainimg/force@2.png 2x, /assets/mainimg/force@3.png 3x",
  },
  {
    title: (
      <p className="card3-title">
        편리하고 <br />
        직관적인 UI 디자인
      </p>
    ),
    subtitle: (
      <p className="card3-subtitle">
        판매화면에서 손쉽게 <br />
        신상품 등록 및 판가변경 가능
      </p>
    ),
    src: "/assets/mainimg/convenient.png",
    setSrc:
      "/assets/mainimg/convenient@2.png 2x, /assets/mainimg/convenient@3.png 3x",
  },
];

const section4Arr = [
  {
    src: "naverpay",
  },
  {
    src: "kakaopay",
  },
  {
    src: "samsungpay",
  },
  {
    src: "zeropay",
  },
  {
    src: "apple",
  },
];
const serviceMenuArr = [
  {
    svg: <Boxsvg />,
    menuTitle: "신상품 등록",
    menuSubTitle: "판매에서 신상품등록 지원",
    src: "posregistration",
  },
  {
    svg: <Clicksvg />,
    menuTitle: "상품정보",
    menuSubTitle: "실시간재고 상품이미지 상품규격 등",
    src: "productinfo",
  },
  {
    svg: <Purchasesvg />,
    menuTitle: "매입 등록",
    menuSubTitle: "간단한 매입등록과 결제를 동시에",
    src: "registraion",
  },
  {
    svg: <Cardsvg />,
    menuTitle: "빠른 카드결제 응답",
    menuSubTitle: "딜레이 없는 빠른 결제 응답",
    src: "cardanswer",
  },
  {
    svg: <Pricesvg />,
    menuTitle: "가격 변경",
    menuSubTitle: "판매에서 상품 가격을 즉시 변경",
    src: "pricechange",
  },
  {
    svg: <Chitsvg />,
    menuTitle: "전표 반품 및 불러오기",
    menuSubTitle: "전표 선택으로 반품 및 불러오기",
    src: "returnimport",
  },
];
const serviceCeoMenuArr = [
  {
    svg: <Marketsvg />,
    menuTitle: "판매가 쉽고 간단해요",
    menuSubTitle: "한 화면에서 판매 및 결제 완료",
    src: "sale",
  },
  {
    svg: <Categorysvg />,
    menuTitle: "상품 터치키가 많아서 좋아요",
    menuSubTitle: "카테고리에 52개 총 3016개의 상품등록",
    src: "touch",
  },
  {
    svg: <Checksvg />,
    menuTitle: "판매 터치키 입력이 간편해요",
    menuSubTitle: "간편한 판매 터치키 입력으로 효율 UP",
    src: "saletouch",
  },
  {
    svg: <Uploadsvg />,
    menuTitle: "액셀 상품등록",
    menuSubTitle: "액셀만 업로드해도 상품등록 끝!",
    src: "exelproduct",
  },
  {
    svg: <Backsvg />,
    menuTitle: "출장 영업에 좋아요",
    menuSubTitle: "인터넷이 없어도 문제없어요!",
    src: "business",
  },
];
const section7Arr = [
  {
    src: "noficationtalk",
    step: "step 01",
    title: "알림톡 발송",
  },
  {
    src: "productdetail",
    step: "step 02",
    title: "고객주문",
  },
  {
    src: "orderconfirmation",
    step: "step 03",
    title: "주문확인 및 배송",
  },
  {
    src: "orderslip",

    step: "step 04",
    title: "주문 전표 등록",
  },
];
const slideArr = [
  {
    title: `카드 단말기 구매하면
포스프로그램이 평생 무료`,
    subtitle: `카드 단말기를 구매 시 포스 프로그램 무료 제공으로
요금 걱정없이 편하게 사용하세요`,
    src: "applepay",
  },
  {
    title: `매장 오픈,
뭉치 포스와 함께하세요`,
    subtitle: `업종 맞춤으로 제공되는
뭉치 포스와 함께하세요`,
    src: "posimg",
  },
  {
    title: `윈도우 시스템의 강력한 성능,
교육이 필요없는 간결한 UI`,
    subtitle: `교육이 필요없는 간결한 UI 디자인으로
처음 사용해도 쉽게 사용할 수 있습니다`,
    src: "poseducation",
  },
];
