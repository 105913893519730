import React, { Fragment, useCallback, useEffect, useState } from "react";
import PosType from "../../components/PosType/PosType";
import { UserAgentType } from "../../interface/common/common.inferface";
import "./css/index.css";
import Bottom from "../../components/Bottom/Bottom";
import Footer from "../../components/Footer/Footer";
import Technology from "../../components/Technology/Technology";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { LoadingChange, PopupChange } from "../../reducer/config";
import DownPopup from "../../components/LoginPopup/DownPopup";
import { AuthType } from "../../reducer/auth";
import { addDoc, collection, getDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { ReactComponent as AsImg1Svg } from "./svg/asimg1.svg";
import { ReactComponent as AsImg2Svg } from "./svg/asimg2.svg";
import { ReactComponent as AsImg3Svg } from "./svg/asimg3.svg";
import { ReactComponent as MoneySvg } from "./svg/money.svg";
import { ReactComponent as ArrowSvg } from "./svg/arrow.svg";

type Props = {
  userAgent: UserAgentType;
};

const Download = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const authData = useSelector<RootState, AuthType>((state) => state.auth);

  const [file, setfile] = useState<any>(undefined);
  console.log(file);
  const CheckDown = useCallback(() => {
    if (authData.uid) {
      console.log(file);
      if (file) {
        const link = document.createElement("a");
        link.href = file.url.url;
        link.download = "skpossetup.exe";
        document.body.appendChild(link);
        link.click();
        link.remove();
        // const url = window.URL.createObjectURL(file);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "skpos.exe";
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
        // window.URL.revokeObjectURL(url); // URL 해제
      } else {
        alert("프로그램 불러오기 실패");
      }
      let obj = Object.assign({}, authData);
      obj.timestamp = Date.now();
      addDoc(collection(db, "down"), obj).catch((err) => {
        console.log(err);
      });
    } else {
      let obj = {
        // title,
        email: "-",
        social: "-",
        tel: "-",
        name: "비회원",
        uid: "-",
        timestamp: Date.now(),
      };
      addDoc(collection(db, "down"), Object.assign(obj));
      dispatch(
        PopupChange({
          active: true,
          type: "down",
        })
      );
    }
  }, [authData, dispatch, file]);

  useEffect(() => {
    getDoc(doc(db, "config", "pos")).then((res) => {
      if (res.exists()) {
        const val = res.data();
        setfile(val);
      }
    });
    return () => {};
  }, []);
  return (
    <main className="download-page">
      <PosType
        colorText="다운로드"
        Active={CheckDown}
        mainText={<>누구나 100세까지 평생 무료</>}
        subText=""
        src="/assets/download/mainimg.png"
        srcSet="/assets/download/mainimg@2x.png, /assets/download/mainimg@3x.png"
        type="button"
        buttonText="다운로드"
      />
      <section className="good-choice-section">
        <div className="good-choice-wrapper">
          {userAgent === "pc" ? (
            <>
              <div className="containers">
                <div className="text-container">
                  <p className="color-text">Good Choice</p>
                  <p className="text">{`우리 가게를 위한
최고의 선택 `}</p>
                  {/* <p className="sub-text">
                    {`합리적인 가격과 편리한 기능이 탑재된
뭉치 포스를 만나보세요
                  `}
                  </p> */}
                </div>
                <div className="card-container">
                  {cardArr.map(({ svg, title, subtitle }, idx) => (
                    <div className="card" key={idx}>
                      <img
                        src={`/assets/download/choice/${svg}.svg`}
                        alt={svg}
                      />
                      <div className="title-container">
                        <p className="title">{title}</p>
                        <p className="subtitle">{subtitle}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="img-container">
                <img
                  className="pos-img"
                  src="/assets/download/posimg.png"
                  srcSet="/assets/download/posimg@2x.png 2x, /assets/download/posimg@3x.png 3x"
                  alt=""
                />
              </div>
            </>
          ) : (
            <div className="containers">
              <div className="text-container">
                <p className="color-text">Good Choice</p>
                <p className="text">{`우리 가게를 위한
최고의 선택 `}</p>
                {/* <p className="sub-text">
                  {`합리적인 가격과 편리한 기능이 탑재된
뭉치 포스를 만나보세요
                  `}
                </p> */}
              </div>
              <div className="card-container">
                {cardArr.map(({ svg, title, subtitle }, idx) => (
                  <div className="card" key={idx}>
                    <img src={`/assets/download/choice/${svg}.svg`} alt={svg} />
                    <div className="title-container">
                      <p className="title">{title}</p>
                      <p className="subtitle">{subtitle}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="img-container">
                <img
                  className="pos-img"
                  src="/assets/download/posimg.png"
                  srcSet="/assets/download/posimg@2x.png 2x, /assets/download/posimg@3x.png 3x"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
      </section>

      {/* <section className="as-section">
        <div className="as-wrapper">
          <div className="text-container">
            <p className="color-text">Problem</p>
            <p className="text">{`A/S 발생시
대체포스를 보내드립니다`}</p>
          </div>
          <div className="card-container">
            {asArr.map(({ svg, title, arrow }, idx) => (
              <Fragment key={idx}>
                <div className="card">
                  <div className="circle">{svg}</div>
                  <p className="title">{title}</p>
                </div>
                {idx !== asArr.length - 1 && <ArrowSvg className="arrow" />}
              </Fragment>
            ))}
          </div>
          <div className="bubble">
            <MoneySvg />
            <div className="detail-text">
              <p className="color-text">
                대체포스 대여 비용을 청구하지 않습니다
              </p>
              <p className="text">
                발송 및 회수는 택배로 진행합니다 (왕복택배비청구)
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="pos-service-section">
        <div className="pos-service-wrapper">
          <div className="text-container">
            <p className="color-text">쉽게, 더 쉽게!</p>
            <p className="text">
              {userAgent === "mb"
                ? `누구나, 오늘 출근한 알바도, 
뚝닥뚝닥 포스 교육이 
필요없습니다`
                : `누구나, 오늘 출근한 알바도, 뚝닥뚝닥
포스 교육이 필요없습니다`}
            </p>
          </div>
          <div className="card-container">
            {posserviceArr.map((item, idx) => (
              <div className="card" key={idx}>
                <img src={item.src} srcSet={item.srcSet} alt="" />
                <div className="title-container">
                  <div className="color-title">{item.title}</div>
                  <div className="sub-title">{item.subtitle}</div>
                  <hr />
                  <div className="lists">
                    <p className="list">{item.list}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Technology />
      <Bottom />
      <Footer />
      <DownPopup />
    </main>
  );
};

export default Download;

const cardArr = [
  {
    svg: "window",
    title: "윈도우 전용",
    subtitle: "윈도우 10 이상",
  },
  {
    svg: "pos",
    title: "PC 포스기",
    subtitle: "노트북, 데스크탑 PC 포스기",
  },
  {
    svg: "screen",
    title: "듀얼 모니터",
    subtitle: "고객용 듀얼 모니터",
  },
  {
    svg: "bar",
    title: "바코드 텍 발행",
    subtitle: "편리한 바코드 텍 발행",
  },
];
const asArr = [
  {
    svg: <AsImg1Svg />,
    title: (
      <>
        고장난 포스 <br />
        A/S 접수 후 <br /> 상담 진행
      </>
    ),
    arrow: <ArrowSvg />,
  },
  {
    svg: <AsImg2Svg />,
    title: (
      <>
        대체 포스 발송 <br />
        고장난 포스회수
      </>
    ),
    arrow: <ArrowSvg />,
  },
  {
    svg: <AsImg3Svg />,
    title: (
      <>
        A/S 완료 후 원복 <br />
        대체포스회수
      </>
    ),
  },
];
const posserviceArr = [
  {
    src: "/assets/download/service/service1.png",
    srcSet:
      "/assets/download/service/service1@2x.png 2x, /assets/download/service/service1@3x.png 3x",
    title: "누구나 쉽게!",
    subtitle: "사용자 편의성을 높인 UI",
    list: `  • 화면 이동없는 빠른 결제 방식
  • 판매에서 신상품 등록 및 판가 변경
  • 상품정보 확인 기능 ( 이미지 적용 )
  • 구독없는 완전한 기능 사용`,
  },
  {
    src: "/assets/download/service/service2.png",
    srcSet:
      "/assets/download/service/service2@2x.png 2x, /assets/download/service/service2@3x.png 3x",
    title: "편리한 매장 관리",
    subtitle: "상품 카테고리 하나에 58개 상품키",
    list: `  • 다양한 할인 방식
  • 간편하게 매입등록 및 거래처 지급
  • 실시간 재고, 바코드 택 발행
  • 상품 카테고리 52개 지원`,
  },
  {
    src: "/assets/download/service/service3.png",
    srcSet:
      "/assets/download/service/service3@2x.png 2x, /assets/download/service/service3@3x.png 3x",
    title: "고객에게 빠른 서비스 제공",
    subtitle: "즉각적인 결제와 응답",
    list: `  • 한번의 터치로 결제 완료
  • 고객관리 및 포인트 적립 및 사용
  • 이미지 알림톡으로 단골 마케팅`,
  },
];
