import React from "react";
import { Route, Routes } from "react-router-dom";
import MyInquiryContainer from "../page/MyInquiry/container/MyInquiryContainer";
import InquiryWriteContainer from "../page/InquiryWrite/container/InquiryWriteContainer";
import { UserAgentType } from "../interface/common/common.inferface";

type Props = {
  userAgent: UserAgentType;
};

const UserRoutes = ({ userAgent }: Props) => {
  return (
    <Routes>
      <Route
        path="/inquiry"
        element={<MyInquiryContainer userAgent={userAgent} />}
      />
      <Route
        path="/inquiry/write"
        element={<InquiryWriteContainer userAgent={userAgent} />}
      />
    </Routes>
  );
};

export default UserRoutes;
