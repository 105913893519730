import React from "react";
import "./css/index.css";
type Props = {};

function Floating({}: Props) {
  return (
    <a
      href="https://pf.kakao.com/_xnZmiG"
      rel="noreferrer"
      target="_blank"
      className="floating-btn"
    >
      <img
        src="/assets/common/kakao.png"
        srcSet="/assets/common/kakao@2x.png 2x, /assets/common/kakao@3x.png 3x"
        alt=""
      />
    </a>
  );
}

export default Floating;
