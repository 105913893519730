import { useState } from "react";
import { ReactComponent as Arrowsvg } from "./svg/arrow.svg";
import "./css/index.css";
type MenuArr = {
  svg: React.ReactNode;
  menuTitle: string;
  menuSubTitle: string;
  src: string;

}
type Props = {
  type: "work" | "ceo";
  title: string;
  thinSubtitle: string;
  boldSubtitle: string;
  menuArr: MenuArr[];

};

const ServiceSpotlight = ({
  type,
  title,
  thinSubtitle,
  boldSubtitle,
  menuArr,
}: Props) => {
  const [now, setnow] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const MenuListClick = (idx: number) => {
    setnow(idx);
    setSelectedItem(idx);
  }
  return (
    <section
      className={`service-spotlight-section ${type === "ceo" && "service-spotlight-ceo"
        }`}
    >
      <div className="service-spotlight-container">
        <div className="title-container">
          <div className={`title ${type === "ceo" && "title-ceo"}`}>
            {title}
          </div>
          <div className="subtitle">
            <p>{thinSubtitle}</p>
            <p>{boldSubtitle}</p>
          </div>
        </div>
        <div className="use-introduction-container">
          <div className="menu-card-container">
            {menuArr.map((item, idx) => (
              <button
                className={`
                    menu-card
                    ${idx === now && type === "work"
                    ? "menu-card-click"
                    : idx === now &&
                    type === "ceo" && (
                      "menu-card-click-ceo"
                    )

                  } `}
                key={idx}
                onClick={() => MenuListClick(idx)}
              >
                {item.svg}
                <div className="menu-title-container">
                  <p className="card-title">{item.menuTitle}</p>
                  <p className="card-subtitle">{item.menuSubTitle}</p>
                </div>
                <Arrowsvg className="arrow" />
              </button>
            ))}
          </div>
          <div className="pos-container">
            <div className="pos-title-container">
              <div
                className={`pos-title-img ${type === "ceo" && "pos-title-img-ceo"
                  }`}
              >
                {menuArr[selectedItem].svg}
              </div>
              <div className="pos-title">
                <p
                  className={`pos-main-title  ${type === "ceo" && "pos-main-title-work"
                    }`}
                >
                  {menuArr[selectedItem].menuTitle}
                </p>
                <p className="pos-sub-title">{menuArr[selectedItem].menuSubTitle}</p>
              </div>
            </div>
            <img
              src={`/assets/mainimg/${menuArr[selectedItem].src}.png`}
              srcSet={`/assets/mainimg/${menuArr[selectedItem].src}@2x.png 2x, /assets/mainimg/${menuArr[selectedItem].src}@3x.png 3x`}
              className="pos-main-img"
            />

          </div>
        </div>
      </div>
    </section>
  );
};
export default ServiceSpotlight;
