import { InquiryInterface } from "../../../interface/list/list.interface";

export const InquiryInitial = () => {
  return     {

      tel: "",
      timestamp: Date.now(),
      isConsulting: false,
      sectors: "",
      reservationTime: "",
      company: "",
      content: "",
      title: "",

    }
};


export function InquiryReducer(
  state: InquiryInterface,
  { payload, type }: { payload: any; type: string }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
          case "tel":
      return {
        ...state,
        tel: payload,
      };
          case "consulting":
      return {
        ...state,
        isConsulting: payload,
      };
  
      case "sectors":
      return {
        ...state,
        sectors: payload,
      };
            case "reservationTime":
      return {
        ...state,
        reservationTime: payload,
      };
            case "company":
      return {
        ...state,
        company: payload,
      };
            case "content":
      return {
        ...state,
        content: payload,
      };
                  case "title":
      return {
        ...state,
        title: payload,
      };
  

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}