import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import { UserAgentType } from "../../interface/common/common.inferface";
import { InquiryInterface } from "../../interface/list/list.interface";
import { telform } from "../../lib/lib";

type Props = {
  userAgent: UserAgentType;
  data: InquiryInterface;
  dispatch: Dispatch<{
    type: string;
    payload: any;
  }>;
  isValid: () => boolean;
  isAgree: boolean;
  setIsAgree: Dispatch<SetStateAction<boolean>>;
  SubmitInquiry: () => void;
};

const InquiryWrite = ({
  userAgent,
  data,
  dispatch,
  isValid,
  isAgree,
  setIsAgree,
  SubmitInquiry,
}: Props) => {
  const { isConsulting, reservationTime, content ,title} = data;
  const inquiryItems: { label: string; id: "sectors" | "company" | "tel" }[] = [
    {
      label: "업종",
      id: "sectors",
    },
    {
      label: "상호",
      id: "company",
    },
    {
      label: "연락처",
      id: "tel",
    },
  ];
  return (
    <main className="inquiry-page">
      <section className="inquiry-section">
        <div className="inquiry-wrapper">
          <div className="inquiry-title-container">
            <p className="inquiry-title">문의 사항을 남겨주세요</p>
            <p className="inquiry-subtitle">
              {userAgent !== "mb"
                ? `상담을 문의해주시면 담당자가 빠른 시일 내에 연락드리도록 하겠습니다`
                : `상담을 문의해주시면 담당자가
 빠른 시일 내에 연락드리도록 하겠습니다`}
            </p>
          </div>
          {/* 문의 내용 작성 란 */}
          <div className="inquiry-write-wrapper">
            <div className="write-wrapper">
              <div className="write-container">
                <p className="write-title">예약 상담 문의 여부</p>
                <button
                  className="write-box"
                  onClick={() => {
                    dispatch({
                      type: "consulting",
                      payload: !isConsulting,
                    });
                  }}
                >
                  <div className="box">
                    {isConsulting && (
                      <img src="/assets/inquiry/check.svg" alt="check" />
                    )}
                  </div>
                  <p>예약 상담 문의</p>
                </button>
              </div>

              <div className="write-container">
                <p className="write-title">예약일자</p>
                <input
                  className="input"
                  placeholder="내용을 입력해주세요"
                  type="number"
                  value={reservationTime}
                  onChange={(e) => {
                    dispatch({
                      type: "reservationTime",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>

              {inquiryItems.map((item,idx)=>(
                <div className="write-container" key={idx}>
                  <p className="write-title">{item.label}</p>
                  <input
                    className="input"
                    value={data[item.id]}
                    placeholder="내용을 입력해주세요"
                    type="text"
                    onChange={(e) => {
                      dispatch({
                        type: item.id,
                        payload:
                          item.id === "tel"
                            ? telform(e.target.value)
                            : e.target.value,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="description-container">
            <p>제목</p>
            <input
              placeholder="내용을 입력해주세요"
              value={title}
              onChange={(e) => {
                dispatch({
                  type: "title",
                  payload: e.target.value,
                });
              }}
            />
          </div>
          <div className="description-container">
            <p>문의내용</p>
            <textarea
              placeholder="내용을 입력해주세요"
              value={content}
              onChange={(e) => {
                dispatch({
                  type: "content",
                  payload: e.target.value,
                });
              }}
            />
          </div>
          <div className="agreement">
            <button
              className="agreement-box"
              onClick={() => {
                setIsAgree((prev) => !prev);
              }}
            >
              <div className="checkbox">
                {isAgree && <img src="/assets/inquiry/check.svg" alt="check" />}
              </div>
              <p>
                {userAgent !== "mb"
                  ? "온라인 문의를 위한 개인정보 수집 및 이용에 동의합니다"
                  : "개인정보 수집 및 이용 동의"}
              </p>
            </button>
          </div>
          <div className="btn-wrapper">
            <button
              className="submit-btn"
              disabled={!isValid()}
              onClick={SubmitInquiry}
            >
              제출하기
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default InquiryWrite;
