import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducer";
import { AuthType } from "../reducer/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../config/firebase";

const UseLinkLog = () => {
  const authData = useSelector<RootState, AuthType>((state) => state.auth);
  const UpdateLinkLog = useCallback(
    async (title: string) => {
      let obj = {
        title,
        email: "-",
        social: "-",
        tel: "-",
        name: "비회원",
        uid: "-",
        timestamp:0
      };
      if (authData.uid) {
        obj = Object.assign(obj, authData);
      }
      obj.timestamp = Date.now()
      await addDoc(collection(db, "log"), obj);
    },
    [authData]
  );
  return { UpdateLinkLog };
};

export default UseLinkLog;
