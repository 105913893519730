import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { rootReducer } from "./reducer";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
const myStore = configureStore({
  reducer: rootReducer,
  devTools: false,
});
export type AppDispatch = typeof myStore.dispatch;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={myStore}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
