import React from "react";
import "./css/index.css";
import PosType from "../../components/PosType/PosType";
import { UserAgentType } from "../../interface/common/common.inferface";
import { InquiryInterface } from "../../interface/list/list.interface";
import InquiryCard from "../../components/InquiryCard/InquiryCard";

type Props = {
  userAgent: UserAgentType;
  data: InquiryInterface[];
  NavWrite: () => void;
  isLogin?: string;
};

const MyInquiry = ({ userAgent, data, NavWrite, isLogin }: Props) => {
  return (
    <main className="myinquiry-page">
      <PosType
        colorText="고객문의"
        mainText="궁금한 사항이 있으신가요?"
        subText={
          <>
            상담을 문의해주시면 담당자가 {userAgent === "mb" && <br />}
            빠른 시일 내에 연락드리도록 하겠습니다
          </>
        }
        src="/assets/myinquiry/postypeimg.png"
        srcSet="/assets/myinquiry/postypeimg@2.png 2x, /assets/myinquiry/postypeimg@3.png 3x"
        type="inquiry"
      />
      <section className="myinquiry-section">
        <div className="myinquiry-wrapper">
          <div className="input-wrapper">
            <p className="text">나의 문의 내역</p>
            <div className="inquiry-search-wrapper">
              <form className="search-form">
                <input
                  className="search-input"
                  type="text"
                  placeholder="검색하기"
                />
                <button className="search-button">
                  <img src="/assets/myinquiry/search/glass.svg" alt="" />
                </button>
              </form>
              {isLogin && (
                <button className="write-link" onClick={NavWrite}>
                  문의글 작성
                </button>
              )}
            </div>
          </div>
          {isLogin && data.length > 0 ? (
            <div className="inquiry-list-wrapper">
              {data.map((item, idx) => {
                return (
                  <InquiryCard key={idx} data={item} userAgent={userAgent} />
                );
              })}
            </div>
          ) : (
            <div className="none-login">
              {isLogin
                ? "작성한 문의가 없습니다"
                : "문의글은 회원가입 후 작성할 수 있습니다"}
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default MyInquiry;
