import React from "react";
import "./css/index.scss";
type Props = {
  colorText: string;
  mainText: React.ReactNode;
  subText?: React.ReactNode;
  src: string;
  srcSet: string;
  type?: "button" | "inquiry";
  buttonText?: string;
  Active?: () => void;
  href?: string;
};
const PosType = ({
  colorText,
  mainText,
  subText,
  src,
  srcSet,
  type,
  buttonText,
  href,
  Active,
}: Props) => {
  return (
    <section className={`section1 ${type === "inquiry" && "inquiry"}`}>
      <div className="section1-wrapper">
        <div className="detail-container">
          <div className="color-text">{colorText}</div>
          <div className="main-text">{mainText}</div>
          <div className="sub-text">{subText}</div>
          {type === "button" && href && (
            <a
              href={href}
              className="button"
              onClick={Active}
              target={"_blank"}
              rel="noreferrer"
            >
              {buttonText}
            </a>
          )}
          {type === "button" && Active && (
            <button className="button" onClick={Active}>
              {buttonText}
            </button>
          )}
        </div>
        <div
          className={`person-img-container ${
            type === "inquiry" && "inquiry-img"
          }`}
        >
          <img src={src} srcSet={srcSet} alt="" />
        </div>
      </div>
    </section>
  );
};
export default PosType;
