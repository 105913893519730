import "./css/index.css";
import { ReactComponent as Callsvg } from "./svg/call.svg";
import { ReactComponent as Emailsvg } from "./svg/email.svg";
import { ReactComponent as Addresssvg } from "./svg/address.svg";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <footer className="footer-container">
        <div className="container">
          <span>SKPOS</span>
          {/* <img
            src="/assets/footerlogo.png"
            srcSet="/assets/footerlogo@2.png, /assets/footerlogo@3.png"
            className="footer-logo"
            alt=""
          /> */}
          <div className="footer-text">
            (주)피플히어로 | 대표 남동현 | 사업자등록번호 493-81-03116
          </div>
          <a href="/privacy" target="blank" className="privacy">개인정보 처리방침</a>
          <div className="description-container">
            {arr.map((item, idx) => (
              <div className="contact" key={idx}>
                {item.img}
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="reserved">©MOOGCHI. All rights reserved.</div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

const arr = [
  {
    img: <Addresssvg />,
    description: "경북 경산시 삼풍로27 (삼풍동) 경북테크노파크 본관 4층 403호",
  },
  {
    img: <Callsvg />,
    description: "1533-4787",
  },
  {
    img: <Emailsvg />,
    description: "skpos0000@gmail.com",
  },
];
