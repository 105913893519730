import React, { useCallback, useEffect } from "react";
import "./css/index.css";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { PopupChange, PopupType } from "../../reducer/config";
import { useDispatch } from "react-redux";
type Props = {};

const DownPopup = (props: Props) => {
  const dispatch = useDispatch();
  const Popup = useSelector<RootState, PopupType>(
    (state) => state.config.popup
  );

  if (Popup.active && Popup.type === "down") {
    return (
      <div className="login-popup">
        <div className="back" />
        <div className="login-wrapper">
          <b className="down-title">로그인 후 다운로드할 수 있습니다</b>
          <div className="down-sub">
            로그인 후 다운로드할 수 있습니다. <br />
            로그인 후 재시도바랍니다.
          </div>
          <div className="down-btn-wrapper">
            <button
              onClick={() => {
                dispatch(
                  PopupChange({
                    active: true,
                    type: "login",
                  })
                );
              }}
            >
              로그인하기
            </button>
            <button
              onClick={() => {
                dispatch(
                  PopupChange({
                    active: false,
                    type: "login",
                  })
                );
              }}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default DownPopup;
