import "./css/index.css";
const AffiliateCompany = () => {
    const fileNames = Array.from({ length: 16 }, (_, i) => `company${i}`);
    return (
        <section className="affiliate-company-section">
            <div className="affiliate-company-container">
                <div className="affiliate-company-img-wrapper">
                    {fileNames.map((fileName, index) => (
                        // 각 파일에 대한 이미지 또는 다른 요소 렌더링
                        <img key={index} src={`
                            /assets/affiliatecompany/${fileName}.png`}
                            srcSet={`
                                /assets/affiliatecompany/${fileName}@2x.png 2x , 
                                /assets/affiliatecompany/${fileName}@3x.png 3x`}
                            alt={`Company ${index}`} />
                    ))}
                    {companyNameArr.map((item, idx) => (
                        <div className="company-name" key={idx}>{item}</div>
                    ))}
                </div>
            </div>
            {/* <div className="evaluation-box">
                <div className="evaluation-title-container">
                    <p className="small-text">POS 및 IC 카드 단말기 부분</p>
                    <p className="bold-text">
                        3년 연속 소비자 만족도 1위 <br />
                        10만 가맹점 돌파
                    </p>
                </div>
                <div className="img-container">
                    <img src="/assets/affiliatecompany/right-img.png"
                        srcSet="/assets/affiliatecompany/right-img@2x.png 2x, /assets/affiliatecompany/rigth-img@3x.png 3x"
                        alt="" />
                </div>
            </div> */}
        </section>
    )
};

export default AffiliateCompany;

const companyNameArr = ['비오비 애완용품', '(주)유진아트문구', '알퐁소베이비파크', '농업법인 청정웰푸드', '일품미가', '1.9만물', '싱싱푸드', '도담푸드 ']