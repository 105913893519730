import { Link } from "react-router-dom";
import "./css/index.css";
const Bottom = () => {
  return (
    <section className="bottom-section">
      <div className="bottom-container">
        <div className="top-img-container">
          <img
            src="/assets/bottom/whitecloud.png"
            srcSet="/assets/bottom/whitecloud@2.png 2x, /assets/bottom/whitecloud@3.png 3x"
            alt=""
          />
        </div>
        <div className="description-container">
          <div className="service-center-container">
            <div className="left-container">
              {/* <img
                src="/assets/bottom/bottom-logo.png"
                srcSet="/assets/bottom/bottom-logo@2x.png 2x, /assets/bottom/bottom-logo@3x.png 3x"
                alt=""
              /> */}
              <div className="inquiry-wrapper">
                <p className="text">궁금한 사항이 있으신가요?</p>
                {/* <Link to='/user/inquiry' className="inquiry-btn">
                                    고객 문의 바로가기
                                </Link > */}
              </div>
            </div>
            <div className="line" />
            <div className="right-container">
              {guideArr.map((item, idx) => (
                <div className="guide-container" key={idx}>
                  <div className="box">{item.title}</div>
                  <p className="description">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bottom-img-container">
          <img
            src="/assets/bottom/bottomcloud.png"
            srcSet="/assets/bottom/bottomcloud@2.png 2x, /assets/bottom/bottomcloud@3.png 3x"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Bottom;

const guideArr = [
  {
    title: "운영시간",
    description: `평일 09:30 ~ 18:00 
점심시간 12:00 ~ 13:00`,
  },
  {
    title: "포스 프로그램 문의",
    description: <p>1533 - 4787</p>,
  },
  // {
  //   title: "카드 단말기 문의",
  //   description: <p>1577 - 5420</p>,
  // },
];
