import React, { useEffect, useState } from "react";
import Skill from "../Skill";
import { UserAgentType } from "../../../interface/common/common.inferface";
import { VideoInterface } from "../../../interface/list/list.interface";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../config/firebase";

type Props = {
  userAgent: UserAgentType;
};

const SkillContainer = ({ userAgent }: Props) => {
  const [VideoData, setVideoData] = useState<VideoInterface[]>([]);
  const [searchData, setSearchData] = useState<VideoInterface[]>([]);
  const [nowCategory, setnowCategory] = useState<number>(0);

  useEffect(() => {
    let clone = VideoData.slice().filter(
      (item) => item.category === nowCategory
    );
    setSearchData(clone);
    return () => { };
  }, [nowCategory, VideoData]);

  useEffect(() => {
    let arr: VideoInterface[] = [];
    getDocs(query(collection(db, "video"), orderBy("timestamp", "desc"))).then(
      (res) => {
        res.forEach((item) => {
          arr.push(item.data() as VideoInterface);
        });
        setVideoData(arr);
      }
    );

    return () => { };
  }, []);

  return <Skill userAgent={userAgent} data={searchData} nowCategory={nowCategory} setnowCategory={setnowCategory} />;
};

export default SkillContainer;
