import { useEffect, useState } from "react";
import Faq from "../Faq";
import { FaqInterface } from "../../../interface/list/list.interface";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../config/firebase";

const FaqContainer = () => {
  const [faqData, setFaqData] = useState<FaqInterface[]>([]);
  const [searchData, setSearchData] = useState<FaqInterface[]>([]);
  const [text, setText] = useState<string>("");

  useEffect(() => {
    let clone = faqData.slice();
    if (text) {
      clone = clone.filter((item) => item.inquiry.includes(text));
    }
    setSearchData(clone);
    return () => {};
  }, [text, faqData]);

  useEffect(() => {
    let arr: FaqInterface[] = [];
    getDocs(query(collection(db, "faq"), orderBy("timestamp", "desc"))).then(
      (res) => {
        res.forEach((item) => {
          arr.push(item.data() as FaqInterface);
        });
        setFaqData(arr);
        setSearchData(arr);
      }
    );

    return () => {};
  }, []);

  return <Faq data={searchData} setText={setText} text={text} />;
};

export default FaqContainer;
