import React, { useEffect, useState } from "react";
import MyInquiry from "../MyInquiry";
import { UserAgentType } from "../../../interface/common/common.inferface";
import { InquiryInterface } from "../../../interface/list/list.interface";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { LoadingChange } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const MyInquiryContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const [InquiryData, setInquiryData] = useState<InquiryInterface[]>([]);

  const NavWrite = () => {
    if (isLogin) {
      navigate("/user/inquiry/write");
    }
  };

  useEffect(() => {
    if (isLogin) {
      console.log(isLogin);
      dispatch(LoadingChange(true));
      let arr: InquiryInterface[] = [];
      getDocs(
        query(collection(db, "inquiry"), where("uid", "==", isLogin))
      ).then((docs) => {
        docs.forEach((item) => {
          arr.push(item.data() as InquiryInterface);
        });
        setInquiryData(arr);
        dispatch(LoadingChange(false));
      });
    }
    return () => {};
  }, [isLogin]);

  return (
    <MyInquiry
      userAgent={userAgent}
      data={InquiryData}
      NavWrite={NavWrite}
      isLogin={isLogin}
    />
  );
};

export default MyInquiryContainer;
