import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Toastface {
  active: boolean;
  content: string;
  type?: string;
}
type StateType = {
  productionPath: "test" | "production";
  popup: PopupType;
  toast: Toastface;
  isLoading: boolean;
};
export interface Toastface {
  active: boolean;
  content: string;
}
export interface PopupType {
  active: boolean;
  type: "login" | "down";
}
const initialState: StateType = {
  productionPath: "test",
  popup: {
    active: false,
    type: "login",
  },
  toast: {
    active: false,
    content: "",
  },
  isLoading: false,
};

const configReducer = createSlice({
  name: "config",
  initialState,
  reducers: {
    PopupChange: (state, action: PayloadAction<PopupType>) => {
      state.popup = action.payload;
    },
    ToastChange: (state, { payload }: { payload: Toastface }) => {
      state.toast = payload;
    },
    LoadingChange: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
  },
});

export const { PopupChange, ToastChange, LoadingChange } =
  configReducer.actions;

export default configReducer.reducer;
