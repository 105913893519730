import React, { useState } from "react";
import "./css/index.css";
import { InquiryInterface } from "../../interface/list/list.interface";
import { formatDate } from "../../lib/lib";
import { UserAgentType } from "../../interface/common/common.inferface";

type Props = {
  data: InquiryInterface;
  userAgent: UserAgentType;
};

const InquiryCard = ({
  data: { title, timestamp, answer, content },
  userAgent,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className={`my-inquiry-card ${isOpen && "open-inquiry-card"}`}>
      <button
        className="inquiry-card-title-section"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="card-title">
          {title}
          {userAgent === "mb" && (
            <>
              <br /> <span>{formatDate(timestamp, "-")}</span>
            </>
          )}
        </div>
        <div className="card-title-right">
          {userAgent !== "mb" && (
            <div className="card-time">{formatDate(timestamp, "-")}</div>
          )}
          <div className="card-state">{answer ? "답변 완료" : "미답변"}</div>
          <div className="arrow-btn">
            <img src="/assets/inquiry/arrow.svg" alt="arrow" />
          </div>
        </div>
      </button>
      <div className="inquiry-card-content-section">
        {content}
        {answer && (
          <div className="answer-box">
            <div className="answer-box-title">관리자 답변입니다</div>
            <div className="answer-box-content">{answer}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InquiryCard;
