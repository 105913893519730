import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type AuthType = {
  email: string;
  name: string;
  tel: string;
  uid: string | undefined;
  social?:"google"|"kakao"|"naver",
  timestamp?: number;
};

const initialState: AuthType = {
  email: "",
  name: "",
  tel: "",
  uid: undefined,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ResetAuth: () => {
      return {
        email: "",
        name: "",
        tel: "",
        uid: "",
      };
    },
    setAuthState(state, action: PayloadAction<AuthType>) {
      return action.payload;
    },
  },
});

export const { ResetAuth, setAuthState } = authReducer.actions;

export default authReducer.reducer;
