import React, { useCallback, useEffect } from "react";
import "./css/index.css";
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import {
  LoadingChange,
  PopupChange,
  PopupType,
  ToastChange,
} from "../../reducer/config";
import { useDispatch } from "react-redux";
import { auth, db } from "../../config/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { AuthType, setAuthState } from "../../reducer/auth";
type Props = {};

const LoginPopup = (props: Props) => {
  const dispatch = useDispatch();
  const Popup = useSelector<RootState, PopupType>(
    (state) => state.config.popup
  );

  const UpdateAuth = (auth: AuthType) => {
    dispatch(setAuthState(auth));
    dispatch(
      PopupChange({
        active: false,
        type: "login",
      })
    );
  };

  const GoogleLogin = useCallback(async () => {
    try {
      let provider = new GoogleAuthProvider();

      // 트위터, 구글
      await setPersistence(auth, browserLocalPersistence);
      const res = await signInWithPopup(auth, provider);
      dispatch(LoadingChange(true));
      const { uid, displayName, email } = res.user as {
        uid: string;
        displayName: string;
        email: string;
      };
      const userData = await getDoc(doc(db, "user", uid));
      if (userData.exists()) {
        const data = userData.data() as AuthType;
        UpdateAuth(data);
      } else {
        let obj: AuthType = {
          name: displayName,
          email,
          timestamp: Date.now(),
          tel: "-",
          uid: res.user.uid,
          social: "google",
        };
        await setDoc(doc(db, "user", res.user.uid), obj);
        UpdateAuth(obj);
      }
      dispatch(LoadingChange(false));
    } catch (err) {
      console.log("err");
    }
  }, []);

  const KaKaoLogin = useCallback(() => {
    const Kakao = (window as any).Kakao;

    Kakao.Auth.login({
      success() {
        dispatch(LoadingChange(true));
        Kakao.API.request({
          url: "/v2/user/me",
          async success(res: any) {
            const {
              kakao_account: { email, name, phone_number },
              id,
            } = res;
            const tel = "0" + phone_number.split(" ")[1];
            await setPersistence(auth, browserLocalPersistence);
            signInWithEmailAndPassword(auth, email, `kakao@${id}`)
              .then(async (res) => {
                if (res.user) {
                  const userData = await getDoc(doc(db, "user", res.user.uid));
                  const data = userData.data() as AuthType;
                  UpdateAuth(data);
                }
                dispatch(LoadingChange(false));
              })
              .catch((err) => {
                if (err.code === "auth/user-not-found") {
                  createUserWithEmailAndPassword(auth, email, `kakao@${id}`)
                    .then(async (res) => {
                      let obj: AuthType = {
                        name,
                        email,
                        timestamp: Date.now(),
                        tel,
                        uid: res.user.uid,
                        social: "kakao",
                      };
                      await setDoc(doc(db, "user", res.user.uid), obj);
                      UpdateAuth(obj);
                      dispatch(LoadingChange(false));
                    })
                    .catch((err) => {
                      dispatch(
                        ToastChange({
                          active: true,
                          content: "다른 방법으로 가입된 이메일입니다",
                        })
                      );
                      dispatch(LoadingChange(false));
                    });
                }
                if (err.code === "auth/wrong-password") {
                  dispatch(
                    ToastChange({
                      active: true,
                      content: "다른 방법으로 가입된 이메일입니다",
                    })
                  );
                }
                dispatch(LoadingChange(false));
              });
          },
          fail(error: any) {
            dispatch(LoadingChange(false));
          },
        });
      },
      fail(error: any) {
        dispatch(LoadingChange(false));
      },
    });
  }, []);
  useEffect(() => {
    const Kakao = (window as any).Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      // Kakao SDK 초기화
      Kakao.init(process.env.REACT_APP_JAVASCRIPT_KEY);
    }
  }, []);
  // const KaKaoLogin = useCallback(() => {
  //   const Kakao = (window as any).Kakao;

  //   if (Kakao && !Kakao.isInitialized()) {
  //     // 환경 변수에서 카카오 JS 키 확인
  //     const kakaoKey = process.env.REACT_APP_JAVASCRIPT_KEY;
  //     if (!kakaoKey) {
  //       console.error("Kakao JS Key is missing.");
  //       return;
  //     }
  //     Kakao.init(kakaoKey);
  //   }

  //   Kakao.Auth.login({
  //     success: async (authObj:any) => {
  //       dispatch(LoadingChange(true));
  //       try {
  //         const res = await Kakao.API.request({
  //           url: "/v2/user/me",
  //         });

  //         const { kakao_account: { email, name, phone_number }, id } = res;
  //         console.log('req', res);
  //         const tel = "0" + phone_number.split(" ")[1];

  //         await setPersistence(auth, browserLocalPersistence);
  //         const userCredential = await signInWithEmailAndPassword(auth, email, `kakao@${id}`);
  //         const userData = await getDoc(doc(db, "user", userCredential.user.uid));

  //         if (userData.exists()) {
  //           const data = userData.data() as AuthType;
  //           UpdateAuth(data);
  //         } else {
  //           // Handle case where user data does not exist
  //         }
  //       } catch (error) {
  //         console.error(error);
  //         // Handle errors appropriately
  //       } finally {
  //         dispatch(LoadingChange(false));
  //       }
  //     },
  //     fail: (error:any) => {
  //       console.error(error);
  //       dispatch(LoadingChange(false));
  //     },
  //   });
  // }, []);

  useEffect(() => {
    if (Popup.active && Popup.type === "login") {
      const naver = (window as any).naver;
      const naverLogin = new naver.LoginWithNaverId({
        clientId: "nxtHuMV5OamofRMsEI3L",
        callbackUrl: `${window.location.origin}/login`,
        isPopup: false,
        callbackHandle: true,
      });
      if (naverLogin) {
        naverLogin.init();
      }
    }
    return () => {};
  }, [Popup]);
  if (Popup.active && Popup.type === "login") {
    return (
      <div className="login-popup">
        <div className="back" />
        <div className="login-wrapper">
          <button
            className="cancel-btn"
            onClick={() => {
              dispatch(
                PopupChange({
                  active: false,
                  type: "login",
                })
              );
            }}
          >
            <img src="/assets/popup/cancel.svg" alt="닫기" />
          </button>
          <div className="title">로그인</div>
          <div className="social-wrapper">
            <button onClick={KaKaoLogin}>
              <img src="/assets/login/kakao.svg" alt="카카오" />
              <b>카카오로 간편 로그인</b>
            </button>
            <button
              id="naverIdLogin_loginButton"
              className="naver"
              type="button"
            >
              <img src="/assets/login/naver.svg" alt="네이버" />
              <b>네이버로 간편 로그인</b>
            </button>
            <button onClick={GoogleLogin}>
              <img src="/assets/login/google.svg" alt="구글" />
              <b>구글로 간편 로그인</b>
            </button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default LoginPopup;
