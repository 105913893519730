import "./css/index.css";
type Props={

}
const Technology = ({}:Props)=>{
    return(
        <section className="technology-wrapper">
            <div className="technology-container">
                <div className="title-container">
                    {/* <p className="color-text">믿고 사용하는 뭉치</p> */}
                    <p className="bold-text">
                        공인기관으로 <br/>
                        <span className="bold-color-text">기술력을 인정</span>받았습니다
                    </p>
                </div>
                <div className="circle-container">
                    {technologyImgArr.map((item,idx)=>(
                        <div className="circle-card" key={idx}>
                            <img src={item.src} srcSet={item.srcSet}  />
                        </div>
                    ))}
                    
                </div>
            </div>
        </section>
    )
}
export default Technology;

const technologyImgArr=[
    {
        src:'/assets/publicinstitutions/techonopark.png',
        srcSet:'/assets/publicinstitutions/techonopark@2x.png 2x, /assets/publicinstitutions/techonopark@3x.png 3x',
    },
    {
        src: '/assets/publicinstitutions/daegyeong.png',
        srcSet: '/assets/publicinstitutions/daegyeong@2x.png 2x, /assets/publicinstitutions/daegyeong@3x.png 3x',
    },
    {
        src: '/assets/publicinstitutions/promotion.png',
        srcSet: '/assets/publicinstitutions/promotion@2x.png 2x, /assets/publicinstitutions/promotion@3x.png 3x',
    },
    {
        src: '/assets/publicinstitutions/kibo.png',
        srcSet: '/assets/publicinstitutions/kibo@2x.png 2x, /assets/publicinstitutions/kibo@3x.png 3x',
    },
    {
        src: '/assets/publicinstitutions/gepa.png',
        srcSet: '/assets/publicinstitutions/gepa@2x.png 2x, /assets/publicinstitutions/gepa@3x.png 3x',
    },
    {
        src: '/assets/publicinstitutions/smallmedium.png',
        srcSet: '/assets/publicinstitutions/smallmedium@2x.png 2x, /assets/publicinstitutions/smallmedium@3x.png 3x',
    },
]