import React, { useEffect } from "react";
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { LoadingChange, PopupChange, ToastChange } from "../../reducer/config";
import { useDispatch } from "react-redux";
import { auth, db } from "../../config/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { AuthType, setAuthState } from "../../reducer/auth";
import { getQueryStringObject } from "../../lib/factory.js";
import { useNavigate } from "react-router-dom";
type Props = {};

const Login = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UpdateAuth = (auth: AuthType) => {
    dispatch(setAuthState(auth));
    dispatch(
      PopupChange({
        active: false,
        type: "login",
      })
    );
    navigate("/");
  };

  useEffect(() => {
    const params = getQueryStringObject();
    const { access_token, expires_in, state, token_type }: any = params;
    if (access_token && expires_in && state && token_type) {
      dispatch(LoadingChange(true));
      const naver = (window as any).naver;
      const naverLogin = new naver.LoginWithNaverId({
        clientId: "nxtHuMV5OamofRMsEI3L",
        callbackUrl: `${window.location.origin}`,
        isPopup: false,
        callbackHandle: true,
      });
      if (naverLogin) {
        naverLogin.init();
      }
      /** naver 로그인 상태 확인 */
      naverLogin.getLoginStatus(async function (status: any) {
        if (status) {
          const { email, id, name } = naverLogin.user;
          const tel = "-";
          try {
            await setPersistence(auth, browserLocalPersistence);
            const login = await signInWithEmailAndPassword(
              auth,
              email,
              `NAVER${id}!`
            );
            const userData = await getDoc(doc(db, "user", login.user.uid));
            const data = userData.data() as AuthType;
            UpdateAuth(data);
            dispatch(LoadingChange(false));
          } catch (err: any) {
            if (err.code === "auth/user-not-found") {
              createUserWithEmailAndPassword(auth, email, `NAVER${id}!`)
                .then(async (res) => {
                  let obj: AuthType = {
                    name,
                    email,
                    timestamp: Date.now(),
                    tel,
                    uid: res.user.uid,
                    social: "naver",
                  };
                  await setDoc(doc(db, "user", res.user.uid), obj);
                  UpdateAuth(obj);
                })
                .catch((err) => {
                  dispatch(
                    ToastChange({
                      active: true,
                      content: "다른 방법으로 가입된 이메일입니다",
                    })
                  );
                });
              dispatch(LoadingChange(false));
              return;
            }
            if (err.code === "auth/wrong-password") {
              dispatch(
                ToastChange({
                  active: true,
                  content: "다른 방법으로 가입된 이메일입니다",
                })
              );
            }
            dispatch(LoadingChange(false));
          }
        } else {
          dispatch(LoadingChange(false));
        }
      });
    }

    return () => {};
  }, [dispatch]);
  return <div>Login</div>;
};

export default Login;
