import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { storage } from "../config/firebase";

export function formatDate(date: number | string, type: string) {
  var d = new Date(Number(date)),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (parseInt(month) < 10) month = "0" + month;
  if (parseInt(day) < 10) day = "0" + day;
  return [year, month, day].join(type);
}
export const telform = (e: string) => {
  const number = e.replace(/[^0-9-]/gi, "");
  const val = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  return val;
};
export function formatNumber(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
export const YmdChange = (yymmdd: string, hour: number) => {
  const year = parseInt(yymmdd.substring(0, 2));
  const month = parseInt(yymmdd.substring(2, 4)) - 1;
  const day = parseInt(yymmdd.substring(4, 6));
  // Date 객체 생성
  const date = new Date(year + 2000, month, day);
  date.setHours(hour);
  date.setMinutes(0);
  return date.getTime();
};

export function dobuleformat(date: number | string, type: string) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (parseInt(month) < 10) month = "0" + month;
  if (parseInt(day) < 10) day = "0" + day;
  return [String(year).substring(2, 4), month, day].join(type);
}

export function FileReaderBase64(
  file: File,
  path: string
): Promise<{ url: string }> {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, path);
    uploadBytes(storageRef, file).then((snapshot) => {
      const downloadURLRef = ref(storage, snapshot.ref.fullPath);
      getDownloadURL(downloadURLRef).then((downloadURL) => {
        resolve({ url: downloadURL });
      });
    });
  });
}

export const CheckReady = (
  date: number,
  {
    hour,
    minit,
  }: {
    hour: number;
    minit: number;
  }
) => {
  const scDate = new Date(date);
  scDate.setHours(hour);
  scDate.setMinutes(minit);
  const now = new Date();
  if (scDate.getTime() <= now.getTime()) {
    return "process";
  } else {
    return "ready";
  }
};

export const GetSelection = () => {
  return selection;
};

let selection: any;

export const SetSelection = (e: Range) => {
  selection = e;
};
