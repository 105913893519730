import { useState, Dispatch, SetStateAction } from "react";
import "./css/index.css";
import { Link } from "react-router-dom";
import { FaqInterface } from "../../interface/list/list.interface";
type Props = {
  data: FaqInterface[];
  text: string;
  setText: Dispatch<SetStateAction<string>>;
};
const Faq = ({ data, text, setText }: Props) => {
  const [toggle, setToggle] = useState<any>({});
  const ToggleOpenClick = (idx: number) => {
    setToggle((prevState: any[]) => ({
      ...prevState,
      [idx]: !prevState[idx],
    }));
  };
  return (
    <main className="faq-page">
      <section className="faq-search-section">
        <div className="faq-search-wrapper">
          {/* 검색부분 */}
          <div className="faq-search-container">
            <div className="faq">FAQ</div>
            <div className="search-form">
              <input
                placeholder="검색하기"
                className="search-input"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
              <button className="search-button">
                <img
                  className="glass-img"
                  src="/assets/search/search.svg"
                  alt="search"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* faq 목록 */}
      <section className="faq-lists-section">
        <div className="faq-lists-wrapper">
          <div className="line" />
          <div className="faq-lists-container">
            <div className="lists-container">
              {data.map(({ inquiry, answer }, idx) => (
                <div
                  key={idx}
                  className={`faq-list-box-wrapper ${
                    toggle[idx] && "open-faq"
                  }`}
                >
                  <button
                    className="list-box"
                    onClick={() => ToggleOpenClick(idx)}
                  >
                    <div className="title-container">
                      <div className="circle">
                        <img src="/assets/search/question.svg" alt="" />
                      </div>
                      <p className="question-title">{inquiry}</p>
                    </div>
                    <div
                      className={`toggle-button ${
                        toggle[idx] && "toggle-open"
                      }`}
                    >
                      <img src="/assets/search/arrow.svg" alt="" />
                    </div>
                  </button>
                  {/* 답변 */}
                  <div className="description-box">
                    <p>{answer}</p>
                  </div>
                </div>
              ))}
            </div>
            <Link className="inquiry-button" to={"/user/inquiry"}>
              <p>더 궁금한 점이 있나요?</p>
              <p>고객문의</p>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Faq;
