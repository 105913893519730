import React from "react";
import "./css/index.css";
import ServiceSpotlight from "../../components/ServiceSpotlight/ServiceSpotlight";
import Technology from "../../components/Technology/Technology";
import AffiliateCompany from "../../components/AffiliateCompany/AffiliateCompany";
import Bottom from "../../components/Bottom/Bottom";
import Footer from "../../components/Footer/Footer";
import PosType from "../../components/PosType/PosType";
import { ReactComponent as Boxsvg } from "../Main/svg/box.svg";
import { ReactComponent as Clicksvg } from "../Main/svg/click.svg";
import { ReactComponent as Pricesvg } from "../Main/svg/price.svg";
import { ReactComponent as Purchasesvg } from "../Main/svg/purchase.svg";
import { ReactComponent as Chitsvg } from "../Main/svg/chit.svg";
import { ReactComponent as Usesvg } from "./svg/use.svg";

type Props = {};

const Industry = (props: Props) => {
  return (
    <main className="industry-page">
      <PosType
        colorText="도입업종"
        subText={
          <>
            도소매업, 음식점, 생활 의류 등<br />
            각 업종에 맞춤형으로 구성된 포스로
            <br />
            간편하게 사용할 수 있습니다
          </>
        }
        mainText={
          <>
            내 가게 업종에 딱맞는
            <br />
            맞춤형 포스
          </>
        }
        src="/assets/industry/person.png"
        srcSet="/assets/industry/person@2.png, /assets/industry/person@3.png"
      />
      <section className="section2">
        <div className="section2-wrapper">
          {/* 도입업종 컨테이너 */}
          <div className="industry-card-container">
            <div className="title-container">
              <div className="sub-title">도입업종</div>
              <div className="main-title">
                다양한 업종에서 <br />
                편리하게 사용할 수 있습니다
              </div>
            </div>
            {/* 카드  */}
            <div className="card-container">
              {industryArr.map((item, idx) => (
                <div className="card" key={idx}>
                  <div className="title">
                    {item.content}
                    <img src={item.src} alt="" />
                  </div>
                  <div className="sub-title">
                    <p className="bold-text">{item.title}</p>
                    <p className="basic-text">{item.subtitle}</p>
                  </div>
                  <div className="sub-title">
                    <p className="bold-text">{item.title2}</p>
                    <p className="basic-text">{item.subtitle2}</p>
                  </div>
                  <div className="sub-title">
                    <p className="bold-text">{item.title3}</p>
                    <p className="basic-text">{item.subtitle3}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 사용후기 컨테이너 */}
          <div className="review-container">
            <div className="title-container">
              <div className="sub-title">도입업종</div>
              <div className="main-title">
                사장님들의 솔직한 후기
                <br />
                {/* <p>인증하는 뭉치 포스</p> */}
              </div>
            </div>
            <div className="card-container">
              {reviewArr.map((item, idx) => (
                <div className="card" key={idx}>
                  <div className="detail-container">
                    <div className="hashtag-box">
                      <div className="hashtag">{item.hashtag}</div>
                      <p className="text">{item.hashtagText}</p>
                    </div>
                    <div className="text-box">
                      <p className="title">{item.title}</p>
                      <p className="sub-title">{item.subtitle}</p>
                    </div>
                  </div>

                  <div className="img-box">
                    <img src={item.src} srcSet={item.srcSet} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <ServiceSpotlight
        type="work"
        title=""
        thinSubtitle="편리한 사용자 중심의"
        boldSubtitle="UI"
        menuArr={serviceMenuArr}
      />
      <Technology />
      <AffiliateCompany />
      <Bottom />
      <Footer />
    </main>
  );
};
export default Industry;

const industryArr = [
  {
    content: "도소매업",
    src: "/assets/industry/cardIcon/box.svg",
    title: "유통매장",
    subtitle: "마트, 편의점, 청과상회, 반찬가게  등",
    title2: "펫 용품, 팬시, 문구점",
    subtitle2: "펫 용품 및 이미용점, 팬시, 문구점 등",
    title3: "주방용품점",
    subtitle3: "식기 및 주방 용품점, 잡화 매장 등",
  },
  {
    content: "생활 의류",
    src: "/assets/industry/cardIcon/clothes.svg",
    title: "의료기",
    subtitle: "의료기 상사 및 소매점",
    title2: "꽃집, 뷰티샵",
    subtitle2: "꽃가게, 미용실 네일샵",
    title3: "의류, 잡화",
    subtitle3: "옷, 신발, 액서사리",
  },
  {
    content: "음식점",
    title: "카페 및 베이커리",
    src: "/assets/industry/cardIcon/spoon.svg",
    subtitle: "커피, 베이커리, 샌드위치 등",
    title2: "분식",
    subtitle2: "김밥, 떡집, 도넛 매장 등",
    title3: "테이크 아웃 매장",
    subtitle3: "푸드트럭, 이동식 커피점 등",
  },
];
const reviewArr = [
  {
    hashtag: "# 카페",
    hashtagText: "Jin cafe",
    title: "포스기 사용이 너무 쉬워요!",
    subtitle: (
      <>
        포스기 사용이 너무 쉬워요. <br />
        기존 포스 프로그램은 결제가 너무 느려 <br />
        매번 불안불안했는데, 이젠 커피 한잔의 <br />
        여유도 생겼어요
      </>
    ),
    src: "/assets/industry/review/review1.png",
    srcSet:
      "/assets/industry/review/review1@2.png, /assets/industry/review1@3.png",
  },
  {
    hashtag: "# 마트",
    hashtagText: "버들마트",
    title: "결제 속도가 정말 빨라요!",
    subtitle: (
      <>
        손님이 많이 몰려도,
        <br />
        상품 가지수가 많아도 걱정없어요 <br />
        결제 속도가 정말 빨라요
      </>
    ),
    src: "/assets/industry/review/review2.png",
    srcSet:
      "/assets/industry/review/review2@2.png, /assets/industry/review2@3.png",
  },
  {
    hashtag: "# 출장영업",
    hashtagText: "도담푸드",
    title: "출장 다니면서 사용합니다",
    subtitle: (
      <>
        노트북에 포스 프로그램 설치하고 <br />
        출장 다니면서 사용합니다
        <br />
        사용법은 딱 보기만 해도 알 수 있을정도로 정말 쉬워요
      </>
    ),
    src: "/assets/industry/review/review3.png",
    srcSet:
      "/assets/industry/review/review3@2.png, /assets/industry/review3@3.png",
  },
  {
    hashtag: "# 과일가게",
    hashtagText: "102번 상회",
    title: (
      <>
        저울무게로 판매를 할 수 있어
        <br />
        너무 좋아요
      </>
    ),
    subtitle: (
      <>
        저울무게로 판매를 할 수 있어 너무 좋아요 <br />
        이런 기능을 무료로 제공해주고, 텍 발행도
        <br />
        되니 정말 편해요
      </>
    ),
    src: "/assets/industry/review/review4.png",
    srcSet:
      "/assets/industry/review/review4@2.png, /assets/industry/review4@3.png",
  },
  {
    hashtag: "# 분식",
    hashtagText: "주홍김밥",
    title: (
      <>
        인터넷이 안되도 <br />
        사용할 수 있어요!
      </>
    ),
    subtitle: (
      <>
        혼자서 일하는 작은 평수인데, 포스때문에
        <br />
        인터넷을 넣기엔 비용이 너무 아까웠어요
        <br />
        뭉치포스는 인터넷이 안되도 사용할 수 있어
        <br />
        매달 몇만원을 절감하고 있어요
        <br />
        집에 있는 중고 PC에 포스 프로그램을 설치해서 사용중이랍니다
      </>
    ),
    src: "/assets/industry/review/review5.png",
    srcSet:
      "/assets/industry/review/review5@2.png, /assets/industry/review5@3.png",
  },
  {
    hashtag: "# 낚시점",
    hashtagText: "생활낚시",
    title: (
      <>
        이미지로 제품 구분이 가능해
        <br />서 업무 효율이 좋아졌어요
      </>
    ),
    subtitle: (
      <>
        생김새가 비슷한 상품이 많지만, 이미지로 <br />
        제품구분이 가능해서 <br />
        업무 효율이 좋아졌어요. 제고 관리가 정확 <br />
        해서 불필요한 제품의 재고가 많이 줄었어요
      </>
    ),
    src: "/assets/industry/review/review6.png",
    srcSet:
      "/assets/industry/review/review6@2.png, /assets/industry/review6@3.png",
  },
];
const serviceMenuArr = [
  {
    svg: <Boxsvg />,
    menuTitle: "직관적인 판매화면",
    menuSubTitle: "사용자 중심의  UI 설계",
    src: "salescreen",
  },
  {
    svg: <Clicksvg />,
    src: "productsale",
    menuTitle: "저울 상품 판매",
    menuSubTitle: "저울무게로 측정하여 판매 가능",
  },
  {
    svg: <Purchasesvg />,
    src: "solution",
    menuTitle: "유통도소매를 위한 완벽 솔루션",
    menuSubTitle: "실시간 재고 확인 가능",
  },
  {
    svg: <Pricesvg />,
    src: "discount",
    menuTitle: "다양한 할인 가능",
    menuSubTitle: "단품 할인, 전체 퍼센트 할인, 임시 판가",
  },
  {
    svg: <Usesvg />,
    src: "easyuse",
    menuTitle: "누구나 쉬운 사용법",
    menuSubTitle: "판매에서 회원 등록 ",
  },
  {
    svg: <Chitsvg />,
    src: "blocksale",
    menuTitle: "대량 판매도 쉽게",
    menuSubTitle: "품명 검색, 체크박스로 한번에 여러개 상품 등록",
  },
];
